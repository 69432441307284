import classNames from "classnames";
import styles from "./styles/GalleryBlock.module.scss";
import {getVideoElementTypeFromItemType} from "../../../../utils/CleversiteUtilities";
import {EditButton} from "../EditButton";
import {SocialItemModalNavigationProps} from "../../../modals/SocialItemModal";
import {handleContentItemClick} from "../../SchoolBlocks/ContentItemBlock/ContentItemBlock";
import React, {RefObject, useContext} from "react";
import {StoreContext} from "../../../../stores/StoreLoader";
import {IReactiveMediaContentItem} from "../../../../stores/SchoolFeedStore";

export function ContentItemVideoComponent(props: {
    item: MediaContentItem
}) {
    const componentType = getVideoElementTypeFromItemType(props.item.type);
    const videoListLength = props.item.json_data.videos?.videos_list?.length || 1; // get largest video format if available
    const source = props.item.json_data.videos?.videos_list[videoListLength - 1].raw_url || "";

    return componentType === 'video' ?
        <video
            className={styles.item}
            src={source}
            controls/> :
        <iframe
            title={props.item.description || props.item.source?.account_title}
            className={styles.item}
            src={source} />
}

const VideoGalleryItem = (props: {
    item: IReactiveMediaContentItem,
    handleDelete: (contentItem: ContentItem) => void,
    ref: RefObject<HTMLDivElement | null>
} & SocialItemModalNavigationProps) => {
    const galleryItemClassName = classNames({
        [styles.galleryItem]: true,
        [styles.galleryItemHidden]: !props.item.published,
    });

    const {userStore} = useContext(StoreContext);

    function handleClick(e) {
        if (e.key && e.key !== "Enter") return;
        e.preventDefault();
        handleContentItemClick(props.item, {
            getItemAtIndex: props.getItemAtIndex,
            totalItemCount: props.totalItemCount,
            initialItemIndex: props.initialItemIndex,
        }, props.handleDelete)
    }

    return (
        <div className={galleryItemClassName} tabIndex={0} onClick={handleClick} onKeyDown={handleClick} ref={props.ref}>
            <ContentItemVideoComponent item={props.item} />
            {userStore.isEditor &&
                <EditButton
                    handleDelete={props.handleDelete}
                    contentItem={props.item}
                    className={styles.editButton}
                />}
        </div>
    );
};

export default VideoGalleryItem;
